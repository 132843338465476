.update-model-info {
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    color: #31394D;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }


  .desc {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
  }
}

.update-model-input-container {
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }

  &.shake-animation {
    animation: shake 0.5s;
  }

  .update-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .update-note {
    margin-top: 12px;
    color: #000;
    text-align: left;
    font-size: 14px;
    .bold {
      font-weight: 700;
    }
  }
}