.profile {
  position: relative;
  margin: 20px 10px;
  height: 23vh;

  .profile-submit-btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.service {
  margin: 20px 10px;
  .building {
    float: left;
    padding-left: 8px;
    margin: 0;
    color: #000;
    font-size: 19px;
    font-weight: 500;
  }
}

.user {
  margin: 20px 10px;
  .admins {
    float: left;
    padding-left: 8px;
    margin: 0;
    color: #000;
    font-size: 19px;
    font-weight: 500;
  }
}