.sidebar {
  height: 100%;
  width: 81px;
  background-color: #ffffff;
  box-shadow: 0 0 5px #e5e9f2;
  left: 0;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a svg {
    padding: 15px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba(123, 207, 207, 1);
      border-radius: 5px;
      box-shadow: 0px 5px 15px #07080880;
    }
  }

  .active-link svg {
    background-color: rgba(123, 207, 207, 1);
    border-radius: 5px;
    box-shadow: 0px 5px 15px #07080880;
  }
}
