.filter-chips {
  display: flex;
  align-content: center;
  margin-top: 10px;
}

#thermostat {
  width: 30vmin;
  height: 30vmin;
  margin: 0 auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dial {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dial.away .dial__ico__leaf {
  visibility: hidden;
}
.dial.away .dial__lbl--target {
  visibility: hidden;
}
.dial.away .dial__lbl--target--half {
  visibility: hidden;
}
.dial.away .dial__lbl--away {
  opacity: 1;
}
.dial .dial__shape {
  transition: fill 0.5s;
}
.dial__ico__leaf {
  fill: #13EB13;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
.dial.has-leaf .dial__ico__leaf {
  display: block;
  opacity: 1;
  pointer-events: initial;
}
.dial__editableIndicator {
  fill: white;
  fill-rule: evenodd;
  opacity: 0;
  transition: opacity 0.5s;
}
.dial--edit .dial__editableIndicator {
  opacity: 1;
}
.dial--state--off .dial__shape {
  fill: #222;
}
.dial--state--heat .dial__shape {
  fill: #FF6961;
}
.dial--state--cool .dial__shape {
  fill: #639FB6;
}
.dial__ticks path {
  fill: rgba(255, 255, 255, 0.3);
}
.dial__ticks path.active {
  fill: rgba(255, 255, 255, 0.8);
}
.dial text {
  fill: white;
  text-anchor: middle;
  font-family: Helvetica, sans-serif;
  alignment-baseline: central;
}
.dial__lbl--target {
  font-size: 120px;
  font-weight: bold;
}
.dial__lbl--target--half {
  font-size: 40px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.1s;
}
.dial__lbl--target--half.shown {
  opacity: 1;
  transition: opacity 0s;
}
.dial__lbl--ambient {
  font-size: 22px;
  font-weight: bold;
}
.dial__lbl--away {
  font-size: 72px;
  font-weight: bold;
  opacity: 0;
  pointer-events: none;
}

.controls-locked {
  color: white;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 57% !important;
  border-radius: 25px;
  background: rgba(99, 159, 182, 0.6);
  height: 200px;
  padding: 7%;
  margin: 120px 0;
}

.average-container {
  width: 68%;
  color: white;
  border-radius: 25px;
  background: rgba(99, 159, 182, 0.6);
  padding-top: 14px;
  margin: 0 auto;

  .average-data {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 4px;
    margin-bottom: 10px;
  }

  .average-rects {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    div {
      margin: 0 5px;
    }

    .average-rect-1 {
      width: 22.205px;
      height: 76.759px;
      border-radius: 30px 30px 0 5px;
      background: #639FB6;
    }

    .average-rect-2 {
      width: 22.205px;
      height: 57.264px;
      border-radius: 30px 30px 0 5px;
      background: #639FB6;
    }

    .average-rect-3 {
      width: 22.205px;
      height: 101.736px;
      border-radius: 30px 30px 0 5px;
      background: #639FB6;
    }

    .average-rect-4 {
      width: 22.205px;
      height: 68.839px;
      border-radius: 30px 30px 0 5px;
      background: #639FB6;
    }
  }
}
