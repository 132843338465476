.navbar {
  top: 0;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgb(228, 239, 247); */
  padding: 28px 55px;
  color: white;
}

.navbar-logo img {
  width: 66%;
}

.navbar-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  margin-right: 20px;
}

.navbar-links a {
  text-decoration: none;
  color: white;
}

.navbar-links a:hover {
  color: black;
}
