.google-btn {
  display: flex !important;
  justify-content: center !important;
  color: #1b2631 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: Montserrat, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  'Liberation Sans', sans-serif !important;
  width: unset !important;
  height: unset !important;
  border-radius: 10px !important;
  margin-top: 11px;
}

.google-btn:hover {
  /* Add your styles here */
  box-shadow: rgba(104,155,94,0.3) 0px 0px 3px 3px !important;
}

.slick-slide > div {
  padding: 0 8px;
}

.slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}
