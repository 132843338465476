
.main-container  {
  .upload-error {
    width: 81%;
    margin: 20px auto;
  }

  .upload-disclaimer {
    margin: 21px auto;
    width: 86%;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
  }

  .documents-title {
    color: #31394D;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 13px 0;
  }

  .document-upload-container {
    display: flex;
    justify-content: start;
    width: 100%;
    flex-direction: column;
    padding: 0 42px;
  }

  .upload-button {
    align-self: flex-end;
    margin-top: 16px;
  }

  .uploaded-documents {
    display: flex;
    flex-direction: column;
    margin-left: 42px;

    .uploaded-document {
      max-width: fit-content;
      margin-bottom: 14px;
    }
  }

  .upload-comment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f6fa;
    margin-top: 16px;

    .upload-comment-disc {
      flex: 1;
      padding: 0 25px;
    }
    .upload-comment-text-field {
      flex: 2;
      margin: 5px 5px 5px 0;
      background: white;
    }
  }
}